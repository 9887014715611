import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { Avatar, Button, Layout, Tabs, Typography, Card } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import useAxios from '../../hooks/useAxios';
import { SERVER_URL } from '../../config';
import { UserLog } from '../../components/forms';
import dayjs from 'dayjs';
import EditUserModal from '../../components/modals/EditModal';
import Axios from 'axios';

const { Content, Sider } = Layout;
const { Text } = Typography;
const { TabPane } = Tabs;

const listRoles = (roles) => {
  if (!Array.isArray(roles)) {
    return;
  }

  let str = '';

  roles.forEach((role, index) => {
    if (index !== roles.length - 1) {
      str += role + ', ';
    } else {
      str += role;
    }
  });

  return str;
};

const ViewUser = (props, editPath) => {
  const { id } = props.match.params;
  const [showModal, setShowModal] = useState(false);
  const currentuser = useContext(UserContext);
  const [user, setUser] = useState(null);
  const [log, fetchLog] = useAxios('', [], currentuser.data.token, 'get');
  const [csv, fetchCSV] = useAxios('', null, currentuser.data.token, 'get');
  const [working, setWorking] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);

  useEffect(() => {
    if (id) {
      const fetchUser = async () => {
        try {
          setWorking(true);

          const { data } = await Axios.get(`${SERVER_URL}/users/${id}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });

          if (data) {
            setUser(data);
          }

          setWorking(false);
        } catch (error) {
          setWorking(false);
          console.log(error);
        }
      };

      fetchUser();
      fetchLog(`${SERVER_URL}/logs/${id}`, []);
    }
  }, [id, fetchTrigger, fetchLog]);

  const getUrl = (itemUrl) => {
    let url = itemUrl.includes('http') ? itemUrl : SERVER_URL + itemUrl;

    return url;
  };

  useEffect(() => {
    if (id) {
      let sevenDayBefor = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
      let sevenDayBeforGet = dayjs(sevenDayBefor).format('YYYY-MM-DD');
      let from = sevenDayBeforGet;
      let to = new Date();
      let fromTo = from + '||' + to;
      fetchCSV(`${SERVER_URL}/logsCSV/${id}/${JSON.stringify(fromTo)}`, []);
    }
  }, [id, fetchCSV]);

  const updateUser = async (data) => {
    if (data) {
      let fromTo = data.from + '||' + data.to;
      fetchCSV(`${SERVER_URL}/logsCSV/${id}/${JSON.stringify(fromTo)}`, []);
    }
  };

  const handleSignOutSubmit = async (event) => {
    event.preventDefault();
    sessionStorage.removeItem('user');
    window.location.href = '/login';
  };

  return (
    <div className='edit-panel'>
      <div className='actions-block'>
        <Link to='/admin/users'>
          <Button type='primary' className='buttonCustom'>All users</Button>
        </Link>
      </div>

      <div className='content content-full-height'>
        {id && !user && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
        {id && user && (
          <Layout className='site-layout-background'>
            <Sider className='site-layout-background' width={300} theme='light'>
              <div className='logo' style={{ height: 'auto', paddingLeft: '15px', paddingRight: '15px' }}>
                {/* <Avatar size={100} src={user.data && user.data.avatar ? getUrl(user.data.avatar.url) : 'Logo'} /> */}

                {/*<Link to={'/admin/edit-user/' + id}>*/}
                <Button
                  type='primary'
                  className='buttonCustom'
                  block
                  icon={<EditOutlined style={{ marginRight: '5px' }} />}
                  style={{ marginTop: '1rem' }}
                  onClick={() => setShowModal(!showModal)}
                >
                  Edit user
                </Button>
                {/*</Link>*/}
              </div>
              <Content style={{ padding: '15px', minHeight: 280, color: '#000' }}>
                <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px' }}>
                  <span className='bold-label'>Email:</span> {user ? user.email : 'Email'}
                </p>
                <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px', textTransform: 'capitalize' }}>
                  <span className='bold-label'>Roles:</span> {user ? listRoles(user.role) : 'Role'}
                </p>
                <p style={{ display: 'block', fontSize: '14px', margin: '0 0 15px' }}>
                  <span className='bold-label'>Status:</span> {user && user.status ? user.status : 'No data'}
                </p>
                {currentuser.data.id === id && (
                  <Button className='log-out-btn' type='secondary' onClick={handleSignOutSubmit}>
                    Log out
                  </Button>
                )}
              </Content>
            </Sider>
            <Content style={{ padding: '0 15px', minHeight: 280, background: '#fff' }}>
              <Tabs defaultActiveKey='1' /* onChange={callback} */>
                <TabPane tab='PROFILE' key='1'>
                  <div className='card-wrapper'>
                    <Card title='User information' bordered={false}>
                      <p>
                        <Text strong>First Name: </Text>
                        {user ? user.firstName : 'No data'}
                      </p>
                      <p>
                        <Text strong>Last Name: </Text>
                        {user ? user.lastName : 'No data'}
                      </p>
                      <p>
                        <Text strong>Email: </Text>
                        {user ? user.email : 'No data'}
                      </p>
                      <p style={{ textTransform: 'capitalize' }}>
                        <Text strong>Roles: </Text>
                        {user ? listRoles(user.role) : 'No data'}
                      </p>
                      <p>
                        <Text strong>Status: </Text>
                        {user && user.status ? user.status : 'No data'}
                      </p>
                      <p>
                        <Text strong>Premium: </Text>
                        {user && user.premium ? 'TRUE' : 'FALSE'}
                      </p>
                      {/* <p>
                        <Text strong>Expo push token: </Text>
                        {user && user.pushTokens && user.pushTokens.length > 0 ? user.pushTokens.join(', ') : 'N/A'}
                      </p> */}
                    </Card>
                  </div>
                </TabPane>

                <TabPane tab='LOGS' key='2'>
                  {log.data.length > 0 ? (
                    <UserLog data={log.data} userId={id} updateHandler={updateUser} csv={csv.data} />
                  ) : (
                    'No Data'
                  )}
                </TabPane>
              </Tabs>
            </Content>
          </Layout>
        )}
      </div>
      <EditUserModal
        user={user}
        showModal={showModal}
        setShowModal={setShowModal}
        profile={true}
        working={working}
        fetchTrigger={fetchTrigger}
        setFetchTrigger={setFetchTrigger}
        setWorking={setWorking}
      />
    </div>
  );
};

export default ViewUser;
