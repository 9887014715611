import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, Input, notification, Spin, Collapse, Card, Checkbox } from 'antd';
import { StepBackwardOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';

const { TextArea } = Input;
const { Panel } = Collapse;

const initialForm = {
  Monday: {
    breakfast: {
      title: null,
      recipe: null,
    },
    snack: {
      title: null,
      recipe: null,
    },
    lunch: {
      title: null,
      recipe: null,
    },
    dinner: {
      title: null,
      recipe: null,
    },
    premium: false,
  },
  Tuesday: {
    breakfast: {
      title: null,
      recipe: null,
    },
    snack: {
      title: null,
      recipe: null,
    },
    lunch: {
      title: null,
      recipe: null,
    },
    dinner: {
      title: null,
      recipe: null,
    },
    premium: false,
  },
  Wednesday: {
    breakfast: {
      title: null,
      recipe: null,
    },
    snack: {
      title: null,
      recipe: null,
    },
    lunch: {
      title: null,
      recipe: null,
    },
    dinner: {
      title: null,
      recipe: null,
    },
    premium: false,
  },
  Thursday: {
    breakfast: {
      title: null,
      recipe: null,
    },
    snack: {
      title: null,
      recipe: null,
    },
    lunch: {
      title: null,
      recipe: null,
    },
    dinner: {
      title: null,
      recipe: null,
    },
    premium: false,
  },
  Friday: {
    breakfast: {
      title: null,
      recipe: null,
    },
    snack: {
      title: null,
      recipe: null,
    },
    lunch: {
      title: null,
      recipe: null,
    },
    dinner: {
      title: null,
      recipe: null,
    },
    premium: false,
  },
  Saturday: {
    breakfast: {
      title: null,
      recipe: null,
    },
    snack: {
      title: null,
      recipe: null,
    },
    lunch: {
      title: null,
      recipe: null,
    },
    dinner: {
      title: null,
      recipe: null,
    },
    premium: false,
  },
  Sunday: {
    breakfast: {
      title: null,
      recipe: null,
    },
    snack: {
      title: null,
      recipe: null,
    },
    lunch: {
      title: null,
      recipe: null,
    },
    dinner: {
      title: null,
      recipe: null,
    },
    premium: false,
  },
};

const populateForm = (data) => {
  let Monday = {};
  let Tuesday = {};
  let Wednesday = {};
  let Thursday = {};
  let Friday = {};
  let Saturday = {};
  let Sunday = {};

  data.forEach((item) => {
    switch (item.name) {
      case 'Monday':
        Monday = {
          breakfast: item.breakfast,
          snack: item.snack,
          lunch: item.lunch,
          dinner: item.dinner,
          premium: item.premium,
        };
        break;
      case 'Tuesday':
        Tuesday = {
          breakfast: item.breakfast,
          snack: item.snack,
          lunch: item.lunch,
          dinner: item.dinner,
          premium: item.premium,
        };
        break;
      case 'Wednesday':
        Wednesday = {
          breakfast: item.breakfast,
          snack: item.snack,
          lunch: item.lunch,
          dinner: item.dinner,
          premium: item.premium,
        };
        break;
      case 'Thursday':
        Thursday = {
          breakfast: item.breakfast,
          snack: item.snack,
          lunch: item.lunch,
          dinner: item.dinner,
          premium: item.premium,
        };
        break;
      case 'Friday':
        Friday = {
          breakfast: item.breakfast,
          snack: item.snack,
          lunch: item.lunch,
          dinner: item.dinner,
          premium: item.premium,
        };
        break;
      case 'Saturday':
        Saturday = {
          breakfast: item.breakfast,
          snack: item.snack,
          lunch: item.lunch,
          dinner: item.dinner,
          premium: item.premium,
        };
        break;
      case 'Sunday':
        Sunday = {
          breakfast: item.breakfast,
          snack: item.snack,
          lunch: item.lunch,
          dinner: item.dinner,
          premium: item.premium,
        };
        break;
    }
  });

  return { Monday, Tuesday, Wednesday, Thursday, Friday, Saturday, Sunday };
};

const EditMealPlan = () => {
  const [form] = Form.useForm();
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const { id } = useParams();
  const [days, setDays] = useState([]);
  const [weekNumber, setWeekNumber] = useState(null);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    const fetchDays = async () => {
      if (id) {
        try {
          setWorking(true);

          const { data } = await Axios.get(`${SERVER_URL}/meal-plans/${id}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });

          if (data) {
            setDays(data.days);
            setWeekNumber(data.weekNumber);
            form.setFieldsValue(populateForm(data.days));
          }

          setWorking(false);
        } catch (error) {
          setWorking(false);
          console.log(error);
          notification.error({
            message: 'Error',
            description: 'Problem while fetching meal plan week data',
            placement: 'bottomRight',
          });
        }
      }
    };

    fetchDays();
  }, [currentuser.data.token]);

  const handleForm = async (values) => {
    try {
      setWorking(true);

      await Axios.patch(`${SERVER_URL}/meal-plans/${id}`, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setWorking(false);

      notification.success({
        message: 'Success',
        description: 'Meal plan updated',
        placement: 'bottomRight',
      });

      history.push('/admin/meal-plan-weeks');
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem while updating meal plan',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='content-wrapper'>
      <Spin spinning={working} tip='Loading...'>
        <div className='actions-block' style={{ marginBottom: '8px' }}>
          <h1>
            <strong>Week {weekNumber}</strong>
          </h1>

          <Button onClick={() => history.push(`/admin/meal-plan-weeks`)} type='primary' className='buttonCustom'>
            <StepBackwardOutlined style={{ marginRight: '5px' }} />
            All Meal Plan Weeks
          </Button>
        </div>

        <div
          style={{
            backgroundColor: 'white',
            paddingTop: '10px',
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingBottom: '10px',
          }}
        >
          <Form
            form={form}
            initialValues={initialForm}
            onFinish={async (values) => handleForm(values)}
            name='editMealPlans'
          >
            <Collapse>
              {days &&
                days.map((day) => {
                  return (
                    <>
                      <Panel header={day.name} key={day.name}>
                        <Form.Item name={[`${day.name}`, 'premium']} label='Premium' valuePropName='checked'>
                          <Checkbox />
                        </Form.Item>

                        <Card title='Breakfast' size='small'>
                          <Form.Item name={[`${day.name}`, 'breakfast', 'title']} label='Title'>
                            <TextArea rows={1} autoSize />
                          </Form.Item>

                          <Form.Item name={[`${day.name}`, 'breakfast', 'recipe']} label='Recipe'>
                            <TextArea />
                          </Form.Item>
                        </Card>

                        <Card title='Snack' size='small'>
                          <Form.Item name={[`${day.name}`, 'snack', 'title']} label='Title'>
                            <TextArea rows={1} autoSize />
                          </Form.Item>

                          <Form.Item name={[`${day.name}`, 'snack', 'recipe']} label='Recipe'>
                            <TextArea />
                          </Form.Item>
                        </Card>

                        <Card title='Lunch' size='small'>
                          <Form.Item name={[`${day.name}`, 'lunch', 'title']} label='Title'>
                            <TextArea rows={1} autoSize />
                          </Form.Item>

                          <Form.Item name={[`${day.name}`, 'lunch', 'recipe']} label='Recipe'>
                            <TextArea />
                          </Form.Item>
                        </Card>

                        <Card title='Dinner' size='small'>
                          <Form.Item name={[`${day.name}`, 'dinner', 'title']} label='Title'>
                            <TextArea rows={1} autoSize />
                          </Form.Item>

                          <Form.Item name={[`${day.name}`, 'dinner', 'recipe']} label='Recipe'>
                            <TextArea />
                          </Form.Item>
                        </Card>
                      </Panel>
                    </>
                  );
                })}
            </Collapse>

            <Form.Item style={{ marginTop: '20px' }}>
              <Button type='primary' htmlType='submit' className='buttonCustom'>
                SAVE
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default EditMealPlan;
