// import Users from '../pages/users/Users';
import UsersList from '../pages/users/UsersList';
import EditUser from '../pages/users/EditUser';
import ViewUser from '../pages/users/ViewUser';
// import Languages from '../pages/languages/Languages';
import Trimesters from '../pages/trimesters/Trimesters';
import Supplements from '../pages/supplements/Supplements';
import NewSupplement from '../pages/supplements/NewSupplement';
import Groceries from '../pages/groceries/Groceries';
import NewGrocery from '../pages/groceries/NewGrocery';
import Introduction from '../pages/true_false/Introduction';
import TF_Items from '../pages/true_false/TF_Items';
import New_TF_Items from '../pages/true_false/New_TF_Items';
import MealPlans from '../pages/mealPlans/MealPlans';
import EditMealPlans from '../pages/mealPlans/EditMealPlans';
import Weeks from '../pages/weeks/Weeks';
import GeneralAdvices from '../pages/groceries/GeneralAdvices';
import SignsAndSymptoms from '../pages/signsAndSymptoms/SignsAndSymptoms';
import NewSign from '../pages/signsAndSymptoms/NewSign';
import SignsAndSymptomsIntro from '../pages/signsAndSymptoms/SignsAndSymptomsIntro';
import WelcomeScreen from '../pages/welcome/WelcomeScreen';
import Exercises from '../pages/exercises/Exercises';
import NewExercise from '../pages/exercises/NewExercise';
import ExerciseIntro from '../pages/exercises/ExerciseIntro';
import CheckboxCategories from '../pages/checkboxCategories/CheckboxCategories';
import NewCheckboxCategory from '../pages/checkboxCategories/NewCheckboxCategory';

const routes = [
  {
    label: 'Users',
    children: [
      {
        label: 'All Users',
        path: '/admin/users',
        component: UsersList,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New User',
        path: '/admin/new-user',
        component: EditUser,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit User',
        path: '/admin/edit-user/:userId',
        component: EditUser,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'View',
        path: '/admin/view-user/:id',
        component: ViewUser,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Welcome Screen',
    children: [
      {
        label: 'Welcome screen content',
        path: '/admin/welcome-screen',
        component: WelcomeScreen,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Trimesters',
    children: [
      {
        label: 'All Trimesters',
        path: '/admin/trimesters',
        component: Trimesters,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Supplements',
    children: [
      {
        label: 'All Supplements',
        path: '/admin/supplements',
        component: Supplements,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New supplement',
        path: '/admin/new-supplement',
        component: NewSupplement,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit supplement',
        path: '/admin/edit-supplement/:id',
        component: NewSupplement,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Groceries',
    children: [
      {
        label: 'All Groceries',
        path: '/admin/groceries',
        component: Groceries,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New grocery',
        path: '/admin/new-grocery',
        component: NewGrocery,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'General Advices',
        path: '/admin/groceries-general-advices',
        component: GeneralAdvices,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'True or False',
    children: [
      {
        label: 'Introduction',
        path: '/admin/true_false-introduction',
        component: Introduction,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Items',
        path: '/admin/true_false-items',
        component: TF_Items,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New item',
        path: '/admin/new-tf-item',
        component: New_TF_Items,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Meal Plans',
    children: [
      {
        label: 'All Meal Plan Weeks',
        path: '/admin/meal-plan-weeks',
        component: MealPlans,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'Edit Meal Plan Week',
        path: '/admin/meal-plan-weeks/:id',
        component: EditMealPlans,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  {
    label: 'Weeks',
    children: [
      {
        label: 'All Weeks',
        path: '/admin/weeks',
        component: Weeks,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Signs&Symptoms',
    children: [
      {
        label: 'All signs and simptoms',
        path: '/admin/signs-and-symptoms',
        component: SignsAndSymptoms,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New signs and simptoms',
        path: '/admin/signs-and-symptoms-new',
        component: NewSign,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Intro text',
        path: '/admin/signs-and-symptoms-intro',
        component: SignsAndSymptomsIntro,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Exercises',
    children: [
      {
        label: 'All exercises',
        path: '/admin/exercises',
        component: Exercises,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New exercise',
        path: '/admin/exercises-new',
        component: NewExercise,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Intro text',
        path: '/admin/exercises-intro',
        component: ExerciseIntro,
        allowed: ['admin'],
        showInMenu: true,
      },
    ],
  },
  {
    label: 'Checkbox Groups',
    children: [
      {
        label: 'All checkbox categories',
        path: '/admin/checkbox-categories',
        component: CheckboxCategories,
        allowed: ['admin'],
        showInMenu: true,
      },
      {
        label: 'New checkbox category',
        path: '/admin/checkbox-categories-new',
        component: NewCheckboxCategory,
        allowed: ['admin'],
        showInMenu: false,
      },
      {
        label: 'Edit checkbox category',
        path: '/admin/checkbox-categories-edit/:id',
        component: NewCheckboxCategory,
        allowed: ['admin'],
        showInMenu: false,
      },
    ],
  },
  // {
  //   label: 'Gallery',
  //   allowed: ['admin'],
  //   children: [
  //     {
  //       label: 'Gallery',
  //       path: '/admin/gallery',
  //       component: Gallery,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //     {
  //       label: 'New Content',
  //       path: '/admin/gallery/new-content',
  //       component: GalleryNewContent,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
  // {
  //   label: 'My profile',
  //   children: [
  //     {
  //       label: 'View',
  //       path: '/admin/view-user/:id',
  //       component: ViewUser,
  //       allowed: ['admin'],
  //       showInMenu: false,
  //     },
  //   ],
  // },
  // {
  //   label: 'Languages',
  //   icon: TranslationOutlined,
  //   children: [
  //     {
  //       label: 'Languages',
  //       path: '/admin/languages',
  //       component: Languages,
  //       allowed: ['admin'],
  //       showInMenu: true,
  //     },
  //   ],
  // },
];

export default routes;
