import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, Button, Select, Result, Radio, notification, Modal, Checkbox } from 'antd';
import UploadBox from '../base/UploadBox';
import { GalleryModal } from './';
import { DeleteOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';

const tailLayout = {
  // wrapperCol: { offset: 8, span: 24 },
  wrapperCol: { span: 24 },
};

const layout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const initialValues = {
  email: undefined,
  firstName: undefined,
  lastName: undefined,
  password: undefined,
  phone: '',
  legalType: 'PRIVATE',
  address: undefined,
  city: undefined,
  zip: undefined,
  country: undefined,
  avatar: undefined,
  role: [],
  premium: false,
  status: null,
};

const UserForm = ({ user, updateHandler, createHandler, SERVER_URL, token, showModal, setShowModal }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [modal, setModal] = useState({ visible: false, formKey: null, limit: 1 });
  const [image, setImage] = useState();
  const isNew = user ? false : true;
  const [deletePop, setDeletePop] = useState();

  const onFinish = (values) => {
    if (isNew) {
      createHandler(values);
    } else {
      updateHandler(values);
    }
    setShowModal(!showModal);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const avatarHandler = () => {
    setModal({ ...modal, visible: true, formKey: 'avatar' });
  };

  const onInsert = (values, formKey) => {
    form.setFieldsValue({ [formKey]: values });
    setImage(values);
  };

  const deleteUserHandler = async (id) => {
    try {
      await Axios.delete(`${SERVER_URL}/users/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'User is deleted.',
        placement: 'bottomRight',
      });

      setTimeout(() => {
        if (id === currentuser.data.id) {
          sessionStorage.removeItem('user');
          currentuser.setLoggedIn(false);
          history.push('/login');
        }
      }, 1200);
    } catch (err) {
      notification.error({
        message: 'Problem with delete. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  // <Form.Item /*label='Avatar'*/ className='upload-wrapper right' name='avatar'>
  //   <UploadBox
  //     editHandler={avatarHandler}
  //     deleteHandler={deleteAvatarHandler}
  //     image={image}
  //     index={0}
  //     name='avatar'
  //   />
  // </Form.Item>;

  return (
    <>
      <div className='dashboard'>
        <div className='panel panel-body'>
          {/* eslint-disable-next-line no-useless-concat */}
          {/* <Card title={`${isNew ? 'New' : 'Edit'} user`} bordered={false}> */}
          {/* <CloseOutlined className='x-btn' onClick={() => setShowModal(false)} /> */}
          <div className='delete-user' onClick={() => setDeletePop(!deletePop)}>
            Delete user <DeleteOutlined />
          </div>

          <Form
            {...layout}
            name='basic'
            initialValues={user}
            onFinish={(values) => onFinish(values, isNew)}
            onFinishFailed={onFinishFailed}
            layout='horizontal'
            form={form}
          >
            <Form.Item
              label='Email'
              name='email'
              rules={[
                {
                  required: true,
                  message: 'Please enter email!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label='First name'
              name='firstName'
              rules={[
                {
                  required: true,
                  message: 'Please enter first name!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label='Last name'
              name='lastName'
              rules={[
                {
                  required: true,
                  message: 'Please enter last name!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label='Phone' name='phoneNumber'>
              <Input />
            </Form.Item>

            {isNew && (
              <Form.Item
                /*label='Password'*/
                name='password'
                rules={[
                  {
                    required: true,
                    message: 'Please enter your password!',
                  },
                ]}
              >
                <Input.Password placeholder='Password' />
              </Form.Item>
            )}

            <Form.Item
              label='Role'
              name='role'
              rules={[
                {
                  required: true,
                  message: 'Please select role!',
                },
              ]}
            >
              <Select
                getPopupContainer={(trigger) => trigger.parentNode}
                dropdownAlign={{ offset: [0, -110] }}
                mode='multiple'
              >
                <Select.Option value='admin'>Admin</Select.Option>
                <Select.Option value='user'>User</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label='Status'
              name='status'
              rules={[
                {
                  required: true,
                  message: 'Please select status!',
                },
              ]}
            >
              <Select getPopupContainer={(trigger) => trigger.parentNode} dropdownAlign={{ offset: [0, -110] }}>
                <Select.Option value='ACTIVE'>Active</Select.Option>
                <Select.Option value='WAITING_FOR_ACTIVATION'>Waiting for activation</Select.Option>
                <Select.Option value='DISABLED'>Disabled</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item label='Premium' name='premium' valuePropName='checked'>
              <Checkbox />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <div>
                <Button type='primary' htmlType='submit' style={{ float: 'right', marginLeft: '15px' }} className='buttonCustom'>
                  {user ? 'Save changes' : 'Add user'}
                </Button>
                <Button type='secondary' style={{ float: 'right' }} onClick={() => setShowModal(!showModal)}>
                  Cancel
                </Button>
              </div>
            </Form.Item>
          </Form>

          {/* </Card> */}

          {modal.visible && (
            <GalleryModal
              className='gallery-modal'
              visible={modal.visible}
              limit={modal.limit}
              formKey={modal.formKey}
              imageSavePath='public/images/users/'
              imageType='data'
              imageHeight={200}
              SERVER_URL={SERVER_URL}
              token={token}
              form={form}
              onCancel={() => setModal({ ...modal, visible: false })}
              onInsert={(values) => onInsert(values, modal.formKey)}
            />
          )}
          <Modal
            className='deleteModal'
            centered={true}
            visible={deletePop}
            onCancel={() => setDeletePop(!deletePop)}
            onOk={() => deleteUserHandler(user._id)}
            okText='Yes'
            okButtonProps={{ className: 'buttonCustom' }}
            cancelText='No'
          >
            <p>Are you sure you want to delete</p>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default UserForm;
