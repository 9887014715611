import React, { useEffect, useContext, useState } from 'react';
import { Form, Button, Input, notification, Spin } from 'antd';
import { StepBackwardOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';

const { TextArea } = Input;

const initialForm = {
  title: null,
  text: null,
};

const Introduction = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [form] = Form.useForm();
  const [working, setWorking] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [intro, setIntro] = useState(null);

  useEffect(() => {
    const fetchIntro = async () => {
      try {
        setWorking(true);

        const { data } = await Axios.get(`${SERVER_URL}/true_false?type=introduction`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (data && data[0]) {
          setIntro(data[0]);
          form.setFieldsValue({ title: data[0].title, text: data[0].text });
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          message: 'Error',
          description: 'Problem while fetching introduction',
          placement: 'bottomRight',
        });
      }
    };

    fetchIntro();
  }, [fetchTrigger, currentuser.data.token]);

  const handleForm = async (values) => {
    try {
      setWorking(true);

      await Axios.patch(`${SERVER_URL}/true_false-intro`, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setFetchTrigger(!fetchTrigger);
      setWorking(false);

      notification.success({
        message: 'Success',
        description: 'Introduction content updated',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem while updating introduction',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='table usersWrapper'>
      <Spin spinning={working} tip='Loading...'>
        <div className='actions-block' style={{ marginBottom: '8px' }}>
          <h2>
            <strong>Introduction</strong>
          </h2>

          <Button onClick={() => history.push(`/admin/true_false-items`)} type='primary' className='buttonCustom'>
            <StepBackwardOutlined style={{ marginRight: '5px' }} />
            All Items
          </Button>
        </div>

        <div style={{ backgroundColor: 'white', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
          <Form
            form={form}
            initialValues={initialForm}
            onFinish={async (values) => handleForm(values)}
            name='true_false'
          >
            <Form.Item
              name='title'
              label='Title'
              rules={[
                {
                  required: true,
                  message: 'Must enter title for introduction',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name='text'
              label='Text'
            // rules={[
            //   {
            //     required: !degree ? false : true,
            //     message: 'Must enter cipcode if degree is selected',
            //   },
            //   {
            //     type: 'string',
            //     min: 2,
            //     max: 7,
            //   },
            // ]}
            >
              <TextArea showCount rows={15} />
            </Form.Item>

            <Form.Item>
              <Button type='primary' htmlType='submit' className='buttonCustom'>
                SAVE
              </Button>
            </Form.Item>
          </Form>

          <p>Updated at: {intro ? new Date(intro.updatedAt).toLocaleString('en-US') : null}</p>
        </div>
      </Spin>
    </div>
  );
};

export default Introduction;
