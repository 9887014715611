import React, { useContext, useEffect } from 'react';
import { Form, Button, Input, Select, notification } from 'antd';
import { StepBackwardOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';

const { TextArea } = Input;

const CATEGORIES = [{ name: 'Vitamin' }, { name: 'Mineral' }, { name: 'Omega-3' }];

const initialForm = {
  title: null,
  category: null,
  text: null,
  sources: null,
};

const NewSupplement = () => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const fetchSupplement = async () => {
        try {
          const { data } = await Axios.get(`${SERVER_URL}/supplements/${id}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });

          if (data) {
            form.setFieldsValue(data);
          }
        } catch (error) {
          console.log(error);
          notification.error({
            message: 'Error',
            description: 'Problem while fetching supplement data',
            placement: 'bottomRight',
          });
        }
      };

      fetchSupplement();
    }
  }, [currentuser.data.token]);

  const handleForm = async (values) => {
    try {
      const method = id ? 'patch' : 'post';
      const route = id ? `${SERVER_URL}/supplements/${id}` : `${SERVER_URL}/supplements`;

      await Axios[method](route, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Success',
        description: id ? 'Supplement updated' : 'New supplement created',
        placement: 'bottomRight',
      });

      history.push('/admin/supplements');
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Error',
        description: id ? 'Problem while editing supplement' : 'Problem while creating new supplement',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='content-wrapper'>
      <div className='actions-block' style={{ marginBottom: '8px' }}>
        <Button onClick={() => history.push(`/admin/supplements`)} type='primary' className='buttonCustom'>
          <StepBackwardOutlined style={{ marginRight: '5px' }} />
          All Supplements
        </Button>
      </div>

      <div style={{ backgroundColor: 'white', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
        <Form form={form} initialValues={initialForm} onFinish={async (values) => handleForm(values)} name='supplement'>
          <Form.Item
            name='title'
            label='Title'
            rules={[
              {
                required: true,
                message: 'Must enter title for new supplement',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name='category'
            label='Category'
            rules={[
              {
                required: true,
                message: 'Must select category for new supplement',
              },
            ]}
          >
            <Select allowClear style={{ width: '200px' }}>
              {CATEGORIES.map((item, index) => (
                <Select.Option key={`${item.name}_${index}`} value={item.name}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name='text'
            label='Text'
          // rules={[
          //   {
          //     required: !degree ? false : true,
          //     message: 'Must enter cipcode if degree is selected',
          //   },
          //   {
          //     type: 'string',
          //     min: 2,
          //     max: 7,
          //   },
          // ]}
          >
            <TextArea rows={15} showCount />
          </Form.Item>

          <Form.Item name='sources' label='Sources'>
            <TextArea rows={5} showCount />
          </Form.Item>

          <Form.Item>
            <Button type='primary' htmlType='submit' className='buttonCustom'>
              SAVE
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default NewSupplement;
