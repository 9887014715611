import React, { useEffect, useContext, useState } from 'react';
import { Form, Button, Input, notification, Spin } from 'antd';
import { StepBackwardOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';

const { TextArea } = Input;

const initialForm = {
  name: null,
  text: null,
};

const GeneralAdvices = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [form] = Form.useForm();
  const [working, setWorking] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [generalAdvices, setGeneralAdvices] = useState(null);

  useEffect(() => {
    const fetchGeneralAdvices = async () => {
      try {
        setWorking(true);

        const { data } = await Axios.get(`${SERVER_URL}/groceries-general-advices`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (data) {
          setGeneralAdvices(data);
          form.setFieldsValue({ name: data.name, text: data.text });
        } else {
          setGeneralAdvices(initialForm);
          form.setFieldsValue({ name: initialForm.name, text: initialForm.text });
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          message: 'Error',
          description: 'Problem while fetching general advices',
          placement: 'bottomRight',
        });
      }
    };

    fetchGeneralAdvices();
  }, [fetchTrigger, currentuser.data.token]);

  const handleForm = async (values) => {
    try {
      setWorking(true);

      await Axios.patch(`${SERVER_URL}/groceries-general-advices`, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setFetchTrigger(!fetchTrigger);
      setWorking(false);

      notification.success({
        message: 'Success',
        description: 'General advices content updated',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem while updating general advices',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='table usersWrapper'>
      <Spin spinning={working} tip='Loading...'>
        <div className='actions-block' style={{ marginBottom: '8px' }}>
          <h2>
            <strong>General Advices</strong>
          </h2>

          <Button onClick={() => history.push(`/admin/groceries`)} type='primary' className='buttonCustom'>
            <StepBackwardOutlined style={{ marginRight: '5px' }} />
            All Groceries
          </Button>
        </div>

        <div style={{ backgroundColor: 'white', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
          <Form
            form={form}
            initialValues={initialForm}
            onFinish={async (values) => handleForm(values)}
            name='generalAdvices'
          >
            <Form.Item
              name='name'
              label='Title'
              rules={[
                {
                  required: true,
                  message: 'Must enter name for general advices',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item name='text' label='Text'>
              <TextArea showCount rows={15} />
            </Form.Item>

            <Form.Item>
              <Button type='primary' htmlType='submit' className='buttonCustom'>
                SAVE
              </Button>
            </Form.Item>
          </Form>

          <p>Updated at: {generalAdvices ? new Date(generalAdvices.updatedAt).toLocaleString('en-US') : null}</p>
        </div>
      </Spin>
    </div>
  );
};

export default GeneralAdvices;
