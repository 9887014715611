import React, { useEffect, useState, useContext } from 'react';
import { Table, notification, Button, Spin, Tooltip, Modal, Input } from 'antd';
import { EditOutlined, ApiFilled, DollarOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';

const MealPlans = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [working, setWorking] = useState(false);
  const [weeks, setWeeks] = useState([]);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [premiumModal, setPremiumModal] = useState({ visible: false, USD: null, EUR: null });

  useEffect(() => {
    const fetchMealData = async () => {
      try {
        setWorking(true);

        const { data } = await Axios.get(`${SERVER_URL}/meal-plans`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (data) {
          setWeeks(data);
        }

        const premiumPriceRes = await Axios.get(`${SERVER_URL}/premium-price`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (premiumPriceRes && premiumPriceRes.data) {
          setPremiumModal({ ...premiumModal, USD: premiumPriceRes.data.USD, EUR: premiumPriceRes.data.EUR });
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          message: 'Error',
          description: 'Problem while fetching meal week data',
          placement: 'bottomRight',
        });
      }
    };

    fetchMealData();
  }, [fetchTrigger, currentuser.data.token]);

  // columns
  const columns = [
    {
      title: 'WEEK NUMBER',
      dataIndex: 'weekNumber',
      key: 'weekNumber',
      align: 'center',
      // ...getColumnSearchProps('weekNumber'),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'right',
      render: (text, record) => new Date(record.createdAt).toLocaleString('en-US'),
    },
    {
      title: 'UPDATED AT',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'right',
      render: (text, record) => new Date(record.updatedAt).toLocaleString('en-US'),
    },
  ];

  columns.push({
    title: 'ACTION',
    key: 'action',
    align: 'center',
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px',
          }}
        >
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
            <Tooltip title='Edit meal plan week'>
              <EditOutlined onClick={() => history.push(`/admin/meal-plan-weeks/${record._id}`)} />
            </Tooltip>
          </div>
        </div>
      );
    },
  });

  const handleInitialGenerate = async () => {
    try {
      setWorking(true);

      await Axios.get(`${SERVER_URL}/generate-meal-plan-weeks`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setWorking(false);
      setFetchTrigger(!fetchTrigger);

      notification.success({
        message: 'Success',
        description: 'Meal plan weeks generated',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: error.response.data.message,
        placement: 'bottomRight',
      });
    }
  };

  const handlePremiumPrice = async () => {
    try {
      setWorking(true);

      await Axios.patch(
        `${SERVER_URL}/premium-price`,
        { USD: premiumModal.USD, EUR: premiumModal.EUR },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      notification.success({
        message: 'Success',
        description: 'Premium price updated',
        placement: 'bottomRight',
      });

      setPremiumModal({ visible: false });
      setWorking(false);
      setFetchTrigger(!fetchTrigger);
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem on editing premium price',
        placement: 'bottomRight',
      });
    }
  };

  //table usersWrapper
  return (
    <div className='content-wrapper'>
      <Spin spinning={working} tip='Loading...'>
        <div className='actions-block' style={{ marginBottom: '8px' }}>
          <Tooltip title='Generate initial meal plan weeks (1 - 4) with default empty values per day of the week and type of meal'>
            <Button
              onClick={handleInitialGenerate}
              type='primary'
              disabled={weeks && weeks.length > 0}
              style={{ backgroundColor: weeks && weeks.length > 0 ? '#eee' : '#5A566F' }}
            >
              <ApiFilled style={{ marginRight: '5px' }} /> Generate Initial Meal Plan Weeks
            </Button>
          </Tooltip>

          <Tooltip title='Change price for premium plan subscription'>
            <Button
              onClick={() => setPremiumModal({ ...premiumModal, visible: true })}
              type='primary'
              style={{ backgroundColor: '#5A566F' }}
            >
              <DollarOutlined style={{ marginRight: '5px' }} /> Edit Premium Price
            </Button>
          </Tooltip>
        </div>

        <div className='table-user-okvir'>
          <Table
            size='middle'
            // onChange={onChangeTable}
            bordered
            columns={columns}
            dataSource={weeks}
            rowKey={(record) => record._id}
            scroll={{ y: 240 }}
          />
        </div>

        <Modal
          centered
          width='70%'
          visible={premiumModal.visible}
          title='Edit premium price'
          okText='SAVE'
          okButtonProps={{ className: 'buttonCustom' }}
          cancelText='CANCEL'
          maskClosable={false}
          onOk={handlePremiumPrice}
          onCancel={() => {
            setPremiumModal({ ...premiumModal, visible: false });
          }}
          confirmLoading={working}
        >
          <div>
            <label htmlFor='USD'>USD: </label>
            <Input
              type='number'
              value={premiumModal.USD}
              name='USD'
              prefix='$'
              onChange={(e) => setPremiumModal({ ...premiumModal, USD: e.target.value })}
            />

            <br />
            <br />

            <label htmlFor='EUR'>EUR: </label>
            <Input
              type='number'
              value={premiumModal.EUR}
              name='EUR'
              prefix='€'
              onChange={(e) => setPremiumModal({ ...premiumModal, EUR: e.target.value })}
            />
          </div>
        </Modal>
      </Spin>
    </div>
  );
};

export default MealPlans;
