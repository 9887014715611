import React, { useEffect, useState, useContext } from 'react';
import { Table, notification, Popconfirm, Button, Spin, Input, Alert, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, SearchOutlined, PlusCircleFilled } from '@ant-design/icons';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import Highlighter from 'react-highlight-words';

const CATEGORIES = [
  { name: 'Things for birth', value: 'things_for_birth' },
  { name: 'Things for baby', value: 'things_for_baby' },
];

const displayCatName = (category) => {
  const foundCat = CATEGORIES.find((cat) => cat.value === category);
  if (foundCat) {
    return foundCat.name;
  } else {
    return 'N/A';
  }
};

const queryClient = new QueryClient();

let filterData = {
  label: null,
  category: null,
};

const CheckboxCategories = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [filter, setFilter] = useState(filterData);
  const [working, setWorking] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);

  useEffect(() => {
    filterData = { ...filterData, ..._.pickBy(_.get(history, 'location.state', null), _.identity) };
    setFilter(filterData);
  }, [history]);

  // react query
  async function fetchCheckboxCategories(filter) {
    const params = _.pickBy(filter, _.identity);

    setWorking(true);

    const { data } = await Axios.get(`${SERVER_URL}/checkbox-categories`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
      params,
    });

    setWorking(false);

    return data;
  }

  const { data, isError, isLoading } = useQuery({
    queryKey: ['checkboxCategories', filter, fetchTrigger],
    queryFn: () => fetchCheckboxCategories(filter),
    keepPreviousData: true,
  });

  // columns
  let searchInput;
  const getColumnSearchProps = (dataIndex) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const prop = dataIndex;
    return {
      filteredValue:
        (_.pickBy(filter, _.identity) && _.pickBy(filter, _.identity)[prop] && [_.pickBy(filter, _.identity)[prop]]) ||
        null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`Search ${prop}`}
              value={selectedKeys && selectedKeys.length > 0 ? selectedKeys : [_.pickBy(filter, _.identity)[prop]]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch(e, selectedKeys, confirm, prop)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />

            <Button
              type='primary'
              onClick={(e) => handleSearch(e, selectedKeys, confirm, prop)}
              size='small'
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>

            <Button onClick={(e) => handleReset(e, clearFilters, prop)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
      filterIcon: (filtered) => {
        filtered = !!_.pickBy(filter, _.identity)[prop];
        return <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />;
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput.select();
          });
        }
      },
      // sorter: true,
      // defaultSortOrder: setDefaultSort(prop),
      // sortDirections: ['ascend', 'descend'],
      render: (text) => {
        return _.pickBy(filter, _.identity)[prop] ? (
          <Highlighter
            title={text}
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[_.pickBy(filter, _.identity)[prop]]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <span title={text}>{text}</span>
        );
      },
    };
  };

  const handleSearch = async (e, selectedKeys, confirm, dataIndex) => {
    e.preventDefault();
    confirm();
    setFilter({ ..._.pickBy(filter, _.identity), [dataIndex]: selectedKeys[0] });
  };

  const handleReset = async (e, clearFilters, prop) => {
    e.preventDefault();
    setFilter({ ...filter, [prop]: undefined });
    clearFilters();
  };

  const handleDelete = async (id) => {
    try {
      setWorking(true);

      await Axios.delete(`${SERVER_URL}/checkbox-categories/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setFetchTrigger(!fetchTrigger);

      setWorking(false);

      notification.success({
        message: 'Success',
        description: 'Checkbox group deleted.',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem with deleting checkbox group. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const columns = [
    {
      title: 'LABEL',
      dataIndex: 'label',
      key: 'label',
      align: 'center',
      ...getColumnSearchProps('label'),
    },
    {
      title: 'CATEGORY',
      dataIndex: 'category',
      key: 'category',
      align: 'center',
      render: (text, record) => displayCatName(record.category),
      // ...getColumnSearchProps('category'),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'right',
      render: (text, record) => new Date(record.createdAt).toLocaleString('en-US'),
    },
    {
      title: 'UPDATED AT',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'right',
      render: (text, record) => new Date(record.updatedAt).toLocaleString('en-US'),
    },
  ];

  columns.push({
    title: 'ACTION',
    key: 'action',
    align: 'center',
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px',
          }}
        >
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
            <Tooltip title='Edit Checkbox Group'>
              <EditOutlined onClick={() => history.push(`/admin/checkbox-categories-edit/${record._id}`)} />
            </Tooltip>
          </div>

          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
            <Tooltip title='Delete Checkbox Group'>
              <Popconfirm
                title='Are you sure you want to delete this checkbox group?'
                onConfirm={async () => handleDelete(record._id)}
                onCancel={() => console.log('Cancel')}
                okText='Yes'
                okButtonProps={{ className: 'buttonCustom' }}
                cancelText='No'
              >
                <DeleteOutlined />
              </Popconfirm>
            </Tooltip>
          </div>
        </div>
      );
    },
  });

  const onChangeTable = (pagination, filters, sorter) => {
    // eslint-disable-next-line array-callback-return
    Object.keys(filters).map((prop) => {
      filter[prop] = Array.isArray(filters[prop]) ? filters[prop][0] : filters[prop];
    });

    setFilter({ ...filter });
  };

  //table usersWrapper
  return (
    <div className='content-wrapper'>
      {isError && <Alert message={'Loading data problem...'} type='error' showIcon closable />}
      <Spin spinning={working} tip='Loading...'>
        <div className='actions-block' style={{ marginBottom: '8px' }}>
          <Button
            onClick={() => history.push(`/admin/checkbox-categories-new`)}
            type='primary'
            className='buttonCustom'
          >
            <PlusCircleFilled style={{ marginRight: '5px' }} /> Add Group
          </Button>
        </div>

        <div className='table-user-okvir'>
          <Table
            size='middle'
            onChange={onChangeTable}
            bordered
            columns={columns}
            dataSource={data && data.length && [...data]}
            rowKey={(record) => record._id}
            // rowSelection={rowSelection}
            // pagination={{
            //   pageSize: filter.pageSize || 10,
            //   total: !isLoading ? data.length : 0,
            //   current: filter.page || 1,
            // }}
            scroll={{ y: 240 }}
          />
        </div>
      </Spin>
    </div>
  );
};

export default function () {
  return (
    <QueryClientProvider client={queryClient}>
      <CheckboxCategories />
    </QueryClientProvider>
  );
}
