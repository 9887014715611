import React, { useState, useContext, useEffect } from 'react';
import {
  Table,
  notification,
  Button,
  Spin,
  Alert,
  Tooltip,
  Modal,
  Input,
  Popconfirm,
  Upload,
  Image,
  message,
} from 'antd';
import { EditOutlined, PlusCircleFilled, SearchOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
// import CKEditor from '../../components/base/CKEditor';
import Highlighter from 'react-highlight-words';
import { useHistory } from 'react-router-dom';

const { TextArea } = Input;

const queryClient = new QueryClient();

let filterData = {
  title: null,
};

const Exercises = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [working, setWorking] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [modal, setModal] = useState({ visible: false, title: null, id: null, text: '', image: null });
  const [filter, setFilter] = useState(filterData);
  // const [oldImage, setOldImage] = useState(null);

  useEffect(() => {
    filterData = { ...filterData, ..._.pickBy(_.get(history, 'location.state', null), _.identity) };
    setFilter(filterData);
  }, [history]);

  // react query
  async function fetchExercises() {
    const params = _.pickBy(filter, _.identity);

    setWorking(true);

    const { data } = await Axios.get(`${SERVER_URL}/exercises`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
      params,
    });

    setWorking(false);

    return data;
  }

  const { data, isError, isLoading } = useQuery({
    queryKey: ['exercises', filter, fetchTrigger],
    queryFn: () => fetchExercises(),
    keepPreviousData: true,
  });

  const handleModal = (record) => {
    setModal({
      visible: true,
      title: record.title,
      id: record._id,
      text: record.text,
      image: record.image,
    });
    // setContent(record.text);
  };

  const handleDelete = async (id) => {
    try {
      setWorking(true);

      await Axios.delete(`${SERVER_URL}/exercises/${id}`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setFetchTrigger(!fetchTrigger);

      setWorking(false);

      notification.success({
        message: 'Success',
        description: 'Exercise deleted.',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem with deleting exercise. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  // columns
  let searchInput;
  const getColumnSearchProps = (dataIndex) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const prop = dataIndex;
    return {
      filteredValue:
        (_.pickBy(filter, _.identity) && _.pickBy(filter, _.identity)[prop] && [_.pickBy(filter, _.identity)[prop]]) ||
        null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder={`Search ${prop}`}
              value={selectedKeys && selectedKeys.length > 0 ? selectedKeys : [_.pickBy(filter, _.identity)[prop]]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch(e, selectedKeys, confirm, prop)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />

            <Button
              type='primary'
              onClick={(e) => handleSearch(e, selectedKeys, confirm, prop)}
              size='small'
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>

            <Button onClick={(e) => handleReset(e, clearFilters, prop)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
      filterIcon: (filtered) => {
        filtered = !!_.pickBy(filter, _.identity)[prop];
        return <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />;
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput.select();
          });
        }
      },
      // sorter: true,
      // defaultSortOrder: setDefaultSort(prop),
      // sortDirections: ['ascend', 'descend'],
      render: (text) => {
        return _.pickBy(filter, _.identity)[prop] ? (
          <Highlighter
            title={text}
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[_.pickBy(filter, _.identity)[prop]]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <span title={text}>{text}</span>
        );
      },
    };
  };

  const handleSearch = async (e, selectedKeys, confirm, dataIndex) => {
    e.preventDefault();
    confirm();
    setFilter({ ..._.pickBy(filter, _.identity), [dataIndex]: selectedKeys[0] });
  };

  const handleReset = async (e, clearFilters, prop) => {
    e.preventDefault();
    setFilter({ ...filter, [prop]: undefined });
    clearFilters();
  };

  const columns = [
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      ...getColumnSearchProps('title'),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'right',
      render: (text, record) => new Date(record.createdAt).toLocaleString('en-US'),
    },
    {
      title: 'UPDATED AT',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'right',
      render: (text, record) => new Date(record.updatedAt).toLocaleString('en-US'),
    },
    {
      title: 'ACTION',
      key: 'action',
      align: 'center',
      render: (text, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
              <Tooltip title='Edit sign'>
                <EditOutlined onClick={() => handleModal(record)} />
              </Tooltip>
            </div>

            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
              <Tooltip title='Delete exercise'>
                <Popconfirm
                  title='Are you sure you want to delete this exercise?'
                  onConfirm={async () => handleDelete(record._id)}
                  onCancel={() => console.log('Cancel')}
                  okText='Yes'
                  okButtonProps={{ className: 'buttonCustom' }}
                  cancelText='No'
                >
                  <DeleteOutlined />
                </Popconfirm>
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  const onOk = async () => {
    try {
      setWorking(true);

      await Axios.patch(
        `${SERVER_URL}/exercises/${modal.id}`,
        { title: modal.title, text: modal.text, image: modal.image },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      // // remove old picture
      // await Axios.post(
      //   `${SERVER_URL}/remove-file/images/${oldImage}`,
      //   {},
      //   {
      //     withCredentials: false,
      //     headers: { Authorization: `Bearer ${currentuser.data.token}` },
      //   },
      // );

      setWorking(false);
      setModal({ visible: false });
      setFetchTrigger(!fetchTrigger);

      notification.success({
        message: 'Success',
        description: 'Exercise updated',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem while updating exercises',
        placement: 'bottomRight',
      });
    }
  };

  const onChangeTable = (pagination, filters, sorter) => {
    // eslint-disable-next-line array-callback-return
    Object.keys(filters).map((prop) => {
      filter[prop] = Array.isArray(filters[prop]) ? filters[prop][0] : filters[prop];
    });

    setFilter({ ...filter });
  };

  return (
    <div className='content-wrapper'>
      {isError && !isLoading && <Alert message={'Loading data problem...'} type='error' showIcon closable />}
      <Spin spinning={working} tip='Loading...'>
        <div className='actions-block' style={{ marginBottom: '8px' }}>
          <Button onClick={() => history.push(`/admin/exercises-new`)} type='primary' className='buttonCustom'>
            <PlusCircleFilled style={{ marginRight: '5px' }} /> Add Exercise
          </Button>
        </div>

        <div className='table-user-okvir'>
          <Table
            size='middle'
            onChange={onChangeTable}
            bordered
            columns={columns}
            dataSource={data}
            rowKey={(record) => record._id}
            // rowSelection={rowSelection}
            // pagination={{
            //   pageSize: filter.pageSize || 5,
            //   total: !isLoading ? data.length : 0,
            //   current: filter.page || 1,
            // }}
            scroll={{ y: 240 }}
          />
        </div>

        <Modal
          centered
          width='80%'
          visible={modal.visible}
          title={
            <span>
              EDIT - <strong>{modal.title}</strong>
            </span>
          }
          okText='SAVE'
          okButtonProps={{ className: 'buttonCustom' }}
          cancelText='CANCEL'
          maskClosable={false}
          onOk={onOk}
          onCancel={() => {
            setModal({ visible: false });
            // setFetchTrigger(!fetchTrigger);
          }}
          confirmLoading={working}
        >
          <div>
            <label htmlFor='name'>Title: </label>
            <Input value={modal.title} name='title' onChange={(e) => setModal({ ...modal, title: e.target.value })} />

            {/* <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Content: </p>
            <CKEditor content={content} setContent={setContent} /> */}

            <br></br>
            <br></br>

            <label htmlFor='text'>Text:</label>
            <TextArea
              rows={15}
              value={modal.text}
              name='text'
              onChange={(e) => setModal({ ...modal, text: e.target.value })}
            />

            <br></br>
            <br></br>

            <label htmlFor='image'>Image:</label>
            <Upload
              headers={{ Authorization: `Bearer ${currentuser.data.token}` }}
              listType='picture-card'
              name='image'
              action={`${SERVER_URL}/upload-image`}
              onChange={(e) => {
                const { status } = e.file;

                if (status === 'done') {
                  setModal({
                    ...modal,
                    image: {
                      url: e.file.response.file.Location,
                      height: e.file.response.file.height,
                      width: e.file.response.file.width,
                    },
                  });
                  // setOldImage(e.file.name);

                  message.success(`${e.file.name} file uploaded successfully.`);
                } else if (status === 'error') {
                  message.error(`${e.file.name} file upload failed.`);
                  if (e.file.response.message) {
                    message.error(e.file.response.message);
                  }
                }
              }}
            >
              <UploadOutlined />
              Upload
            </Upload>

            {modal.image && modal.image.url && (
              <div style={{ display: 'flex' }}>
                <Image width={200} height={200} src={modal.image.url} />
                <Button type='default' onClick={() => setModal({ ...modal, image: null })}>
                  Remove Image
                </Button>
              </div>
            )}
          </div>
        </Modal>
      </Spin>
    </div>
  );
};

export default function () {
  return (
    <QueryClientProvider client={queryClient}>
      <Exercises />
    </QueryClientProvider>
  );
}
