import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, notification, Spin, Result } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { Link, useParams } from 'react-router-dom';

const { Meta } = Card;

const backgroundStyle = {
  width: '100vw',
  height: '100vh',
  backgroundColor: `#FFE98B`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { span: 24 },
};

const loginCardStyle = {
  borderRadius: '20px',
  background: 'rgba(255, 255, 255, 0.8)',
};

const companyLogoStyle = {
  maxWidth: '150px',
};

const ResetPassword = () => {
  // const [password, setPassword] = useState('');
  const [working, setWorking] = useState(false);
  const [roles, setRoles] = useState([]);
  const [finished, setFinished] = useState(false);
  let { passwordToken } = useParams();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        setWorking(true);

        const { data } = await Axios.get(`${SERVER_URL}/user-by-passwordToken/${passwordToken}`, {
          withCredentials: false,
        });

        if (data) {
          setRoles(data.role);
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          message: 'Error',
          description: 'User not found',
          placement: 'bottomRight',
          duration: 3,
        });
      }
    };

    fetchUser();
  }, []);

  const onFinish = async (values) => {
    try {
      const ResetPasswordLink = await Axios.post(
        `${SERVER_URL}/password-reset/${passwordToken}`,
        { password: values.password },
        { withCredentials: false },
      );
      if (ResetPasswordLink.data.message) {
        setFinished(true);
        notification.success({
          message: ResetPasswordLink.data.message,
          // message: 'Password succesfully reseted.',
          placement: 'bottomRight',
        });
      }
    } catch (err) {
      notification.warn({
        message: err.response.data.message,
        placement: 'bottomRight',
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className='log' style={backgroundStyle}>
      <Spin spinning={working}>
        <div className='card-wrapper'>
          <Card className='login-header' bordered={false} style={loginCardStyle}>
            {!finished ? (
              <>
                <img style={companyLogoStyle} className='login-logo' src='/logop.png' alt='Universal Logo' />
                <Meta title='Enter New Password' />
                <Form {...layout} name='basic' onFinish={onFinish} onFinishFailed={onFinishFailed}>
                  <Form.Item name='password' rules={[{ required: true, message: 'Please enter your password!' }]}>
                    <Input placeholder='Password' />
                  </Form.Item>

                  <Form.Item {...tailLayout} style={{ marginBottom: 0 }}>
                    <Button type='primary' style={{ width: '100%', backgroundColor: '#FF82B7' }} htmlType='submit'>
                      Reset
                    </Button>
                  </Form.Item>

                  {roles && roles.length > 0 && roles.includes('admin') && (
                    <Form.Item {...tailLayout} style={{ paddingTop: '30px' }}>
                      <Link to='/login' className='link-forgot-password'>
                        Login
                      </Link>
                    </Form.Item>
                  )}
                </Form>
              </>
            ) : (
              <>
                <Result status='success' title='Password successfully changed' />
                {/* <Meta title='Password successfully changed' /> */}
              </>
            )}
          </Card>
        </div>
      </Spin>
    </div>
  );
};

export default ResetPassword;
