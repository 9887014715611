import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
// import { useHistory } from 'react-router-dom';
import { notification, Drawer /* , Button */ } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { UserContext } from '../../App';
import { SERVER_URL } from '../../config';
import { UserForm } from '../../components/forms';

const EditUserModal = ({ user, showModal, setShowModal, working, setWorking, fetchTrigger, setFetchTrigger }) => {
  const currentuser = useContext(UserContext);

  const updateUser = async (data) => {
    try {
      setWorking(true);

      await Axios.patch(
        `${SERVER_URL}/users/${user._id}`,
        { ...data },
        { withCredentials: false, headers: { Authorization: `Bearer ${currentuser.data.token}` } },
      );

      setWorking(false);
      setFetchTrigger(!fetchTrigger);

      notification.success({
        message: 'User is updated.',
        placement: 'bottomRight',
      });
    } catch (err) {
      setWorking(false);
      console.log(err);
      notification.error({
        message: 'Problem with update. Please try later.',
        placement: 'bottomRight',
      });
    }
  };

  const onClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <div style={{ textAlign: 'center', height: '100%' }}>
        {!user && working && <LoadingOutlined spin style={{ fontSize: '3rem', marginTop: '5rem' }} />}
        {user && (
          <Drawer title={'Edit User'} placement='right' onClose={onClose} visible={showModal} width={420}>
            <UserForm
              user={user}
              updateHandler={updateUser}
              SERVER_URL={SERVER_URL}
              token={currentuser.data.token}
              showModal={showModal}
              setShowModal={setShowModal}
            />
          </Drawer>
        )}
        {!user && <h2 style={{ marginTop: '5rem' }}>Something went wrong :(</h2>}
      </div>
    </>
  );
};

export default EditUserModal;
