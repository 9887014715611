import React, { useEffect, useContext, useState } from 'react';
import { Form, Button, Input, notification, Spin } from 'antd';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';

const { TextArea } = Input;

const initialForm = {
  title: null,
  text: null,
};

const WelcomeScreen = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [form] = Form.useForm();
  const [working, setWorking] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [welcomeScreen, setWelcomeScreen] = useState(null);

  useEffect(() => {
    const fetchWelcomeScreen = async () => {
      try {
        setWorking(true);

        const { data } = await Axios.get(`${SERVER_URL}/welcome-screen`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (data) {
          setWelcomeScreen(data);
          form.setFieldsValue({ title: data.title, text: data.text });
        } else {
          setWelcomeScreen(initialForm);
          form.setFieldsValue({ title: initialForm.title, text: initialForm.text });
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          message: 'Error',
          description: 'Problem while fetching welcome screen content',
          placement: 'bottomRight',
        });
      }
    };

    fetchWelcomeScreen();
  }, [fetchTrigger, currentuser.data.token]);

  const handleForm = async (values) => {
    try {
      setWorking(true);

      await Axios.patch(`${SERVER_URL}/welcome-screen`, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setFetchTrigger(!fetchTrigger);
      setWorking(false);

      notification.success({
        message: 'Success',
        description: 'Welcome screen content updated',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem while updating welcome screen content',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='table usersWrapper'>
      <Spin spinning={working} tip='Loading...'>
        <div className='actions-block' style={{ marginBottom: '8px' }}>
          <h2>
            <strong>Welcome Screen Content</strong>
          </h2>
        </div>

        <div style={{ backgroundColor: 'white', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
          <Form
            form={form}
            initialValues={initialForm}
            onFinish={async (values) => handleForm(values)}
            name='welcomeScreen'
          >
            <Form.Item
              name='title'
              label='Title'
              rules={[
                {
                  required: true,
                  message: 'Must enter title for welcome screen',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item name='text' label='Text'>
              <TextArea showCount rows={15} />
            </Form.Item>

            <Form.Item>
              <Button type='primary' htmlType='submit' className='buttonCustom'>
                SAVE
              </Button>
            </Form.Item>
          </Form>

          <p>Updated at: {welcomeScreen ? new Date(welcomeScreen.updatedAt).toLocaleString('en-US') : null}</p>
        </div>
      </Spin>
    </div>
  );
};

export default WelcomeScreen;
