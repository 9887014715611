import React, { useEffect, useContext, useState } from 'react';
import { Form, Button, Input, notification, Spin } from 'antd';
import { StepBackwardOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';

const { TextArea } = Input;

const initialForm = {
  title: null,
  text: null,
};

const SignsAndSymptomsIntro = () => {
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [form] = Form.useForm();
  const [working, setWorking] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [signsIntro, setSignsIntro] = useState(null);

  useEffect(() => {
    const fetchSASIntro = async () => {
      try {
        setWorking(true);

        const { data } = await Axios.get(`${SERVER_URL}/signs-and-symptoms-intro`, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        });

        if (data) {
          setSignsIntro(data);
          form.setFieldsValue({ title: data.title, text: data.text });
        } else {
          setSignsIntro(initialForm);
          form.setFieldsValue({ title: initialForm.title, text: initialForm.text });
        }

        setWorking(false);
      } catch (error) {
        setWorking(false);
        console.log(error);
        notification.error({
          message: 'Error',
          description: 'Problem while fetching signs and symptoms intro text',
          placement: 'bottomRight',
        });
      }
    };

    fetchSASIntro();
  }, [fetchTrigger, currentuser.data.token]);

  const handleForm = async (values) => {
    try {
      setWorking(true);

      await Axios.patch(`${SERVER_URL}/signs-and-symptoms-intro`, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setFetchTrigger(!fetchTrigger);
      setWorking(false);

      notification.success({
        message: 'Success',
        description: 'Signs and symptons intro content updated',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem while updating signs and symptoms intro content',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='table usersWrapper'>
      <Spin spinning={working} tip='Loading...'>
        <div className='actions-block' style={{ marginBottom: '8px' }}>
          <h2>
            <strong>Signs And Symptoms Intro Content</strong>
          </h2>

          <Button onClick={() => history.push(`/admin/signs-and-symptoms`)} type='primary' className='buttonCustom'>
            <StepBackwardOutlined style={{ marginRight: '5px' }} />
            All Signs and Symptoms
          </Button>
        </div>

        <div style={{ backgroundColor: 'white', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
          <Form
            form={form}
            initialValues={initialForm}
            onFinish={async (values) => handleForm(values)}
            name='signsIntro'
          >
            <Form.Item
              name='title'
              label='Title'
              rules={[
                {
                  required: true,
                  message: 'Must enter title for signs and symptoms intro',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item name='text' label='Text'>
              <TextArea showCount rows={15} />
            </Form.Item>

            <Form.Item>
              <Button type='primary' htmlType='submit' className='buttonCustom'>
                SAVE
              </Button>
            </Form.Item>
          </Form>

          <p>Updated at: {signsIntro ? new Date(signsIntro.updatedAt).toLocaleString('en-US') : null}</p>
        </div>
      </Spin>
    </div>
  );
};

export default SignsAndSymptomsIntro;
