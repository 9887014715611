import React, { useState, useContext } from 'react';
import { Table, notification, Button, Spin, Alert, Tooltip, Modal, Input } from 'antd';
import { EditOutlined, ApiOutlined } from '@ant-design/icons';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
// import CKEditor from '../../components/base/CKEditor';

const { TextArea } = Input;

const queryClient = new QueryClient();

const Trimesters = () => {
  const currentuser = useContext(UserContext);
  const [working, setWorking] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [modal, setModal] = useState({ visible: false, name: null, id: null, number: null, text: '' });
  // const [content, setContent] = useState('');

  // react query
  async function fetchTrimesters() {
    setWorking(true);

    const { data } = await Axios.get(`${SERVER_URL}/trimesters`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    setWorking(false);

    return data;
  }

  const { data, isError, isLoading } = useQuery({
    queryKey: ['trimesters', fetchTrigger],
    queryFn: () => fetchTrimesters(),
    keepPreviousData: true,
  });

  const handleModal = (record) => {
    setModal({ visible: true, number: record.number, name: record.name, id: record._id, text: record.text });
    // setContent(record.text);
  };

  const columns = [
    {
      title: 'NUMBER',
      dataIndex: 'number',
      key: 'number',
      align: 'center',
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
    },
    {
      title: 'ACTION',
      key: 'action',
      align: 'center',
      render: (text, record) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '15px',
            }}
          >
            <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
              <Tooltip title='Edit trimester'>
                <EditOutlined onClick={() => handleModal(record)} />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  const generateTrimesters = async () => {
    try {
      setWorking(true);

      await Axios.get(`${SERVER_URL}/generate-trimesters`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setWorking(false);
      setFetchTrigger(!fetchTrigger);

      notification.success({
        message: 'Success',
        description: 'Initial trimesters generated',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem while generating initial trimesters.',
        placement: 'bottomRight',
      });
    }
  };

  const onOk = async () => {
    try {
      setWorking(true);

      await Axios.patch(
        `${SERVER_URL}/trimesters/${modal.id}`,
        { text: modal.text },
        {
          withCredentials: false,
          headers: { Authorization: `Bearer ${currentuser.data.token}` },
        },
      );

      setWorking(false);
      setModal({ visible: false });
      setFetchTrigger(!fetchTrigger);

      notification.success({
        message: 'Success',
        description: 'Trimester updated',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem while updating trimester',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='content-wrapper'>
      {isError && !isLoading && <Alert message={'Loading data problem...'} type='error' showIcon closable />}
      <Spin spinning={working} tip='Loading...'>
        <div className='actions-block' style={{ marginBottom: '8px' }}>
          <Tooltip title='Generate initial trimesters'>
            <Button onClick={generateTrimesters} type='primary' disabled={data && data.length > 0} style={{ backgroundColor: data && data.length > 0 ? '#eee' : '#5A566F' }}>
              <ApiOutlined style={{ marginRight: '5px' }} />
              Generate Trimesters
            </Button>
          </Tooltip>
        </div>

        <div className='table-user-okvir'>
          <Table
            size='middle'
            // onChange={onChangeTable}
            bordered
            columns={columns}
            dataSource={data}
            rowKey={(record) => record._id}
            // rowSelection={rowSelection}
            // pagination={{
            //   pageSize: filter.pageSize || 10,
            //   total: !isLoading ? data.length : 0,
            //   current: filter.page || 1,
            // }}
            scroll={{ y: 240 }}
          />
        </div>

        <Modal
          centered
          width='80%'
          visible={modal.visible}
          title={
            <span>
              EDIT - <strong>{modal.name}</strong>
            </span>
          }
          okText='SAVE'
          okButtonProps={{ className: 'buttonCustom' }}
          cancelText='CANCEL'
          maskClosable={false}
          onOk={onOk}
          onCancel={() => {
            setModal({ visible: false });
            setFetchTrigger(!fetchTrigger);
          }}
          confirmLoading={working}
        >
          <div>
            {/* <p style={{ fontSize: 15, marginTop: 20, marginRight: 10 }}>Content: </p>
            <CKEditor content={content} setContent={setContent} /> */}
            <label htmlFor='text'>Text:</label>
            <TextArea
              rows={15}
              value={modal.text}
              name='text'
              onChange={(e) => setModal({ ...modal, text: e.target.value })}
            />
          </div>
        </Modal>
      </Spin>
    </div>
  );
};

export default function () {
  return (
    <QueryClientProvider client={queryClient}>
      <Trimesters />
    </QueryClientProvider>
  );
}
