import React, { useContext } from 'react';
import { Form, Button, Input, notification } from 'antd';
import { StepBackwardOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';

const { TextArea } = Input;

const initialForm = {
  title: null,
  text: null,
};

const New_TF_Items = () => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [form] = Form.useForm();

  const handleForm = async (values) => {
    try {
      await Axios.post(`${SERVER_URL}/true_false-item`, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Success',
        description: 'Item created',
        placement: 'bottomRight',
      });

      history.push('/admin/true_false-items');
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem while creating new grocery',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='content-wrapper'>
      <div className='actions-block' style={{ marginBottom: '8px' }}>
        <Button onClick={() => history.push(`/admin/true_false-items`)} type='primary' className='buttonCustom'>
          <StepBackwardOutlined style={{ marginRight: '5px' }} />
          All Items
        </Button>
      </div>

      <div style={{ backgroundColor: 'white', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
        <Form form={form} initialValues={initialForm} onFinish={async (values) => handleForm(values)} name='items'>
          <Form.Item
            name='title'
            label='Title'
            rules={[
              {
                required: true,
                message: 'Must enter title of the new item',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name='text'
            label='Text'
          // rules={[
          //   {
          //     required: !degree ? false : true,
          //     message: 'Must enter cipcode if degree is selected',
          //   },
          //   {
          //     type: 'string',
          //     min: 2,
          //     max: 7,
          //   },
          // ]}
          >
            <TextArea showCount rows={15} />
          </Form.Item>

          <Form.Item>
            <Button type='primary' htmlType='submit' className='buttonCustom'>
              SAVE
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default New_TF_Items;
