import React, { useState, useEffect } from 'react';
import { Card } from 'antd';
import { useParams } from 'react-router-dom';
import { SERVER_URL } from '../../config';
import useAxios from '../../hooks/useAxios';

const { Meta } = Card;

const backgroundStyle = {
  width: '100vw',
  height: '100vh',
  backgroundColor: `#FFE98B`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

const loginCardStyle = {
  borderRadius: '20px',
  background: 'rgba(255, 255, 255, 0.8)',
};

const companyLogoStyle = {
  maxWidth: '150px',
};

const EmailVerification = () => {
  let { emailToken } = useParams();
  console.log('token', emailToken);
  console.log('SERVER_URL', SERVER_URL);
  const [invalidToken, setInvalidToken] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [emailVerification, fetchEmailVerification] = useAxios('', [], '', 'get');

  useEffect(() => {
    fetchEmailVerification(`${SERVER_URL}/email-verification/${emailToken}`, {});
    if (emailVerification.data && emailVerification.data.message === 'Succesfully activated account') {
      if (emailVerification.data.role && emailVerification.data.role.includes('admin')) {
        setIsAdmin(true);
        setTimeout(() => {
          window.location.href = '/';
        }, 3000);
      }
    } else if (emailVerification.isError) {
      setInvalidToken(true);
    }
  }, [emailToken, emailVerification, emailVerification.data, fetchEmailVerification]);

  return (
    <div className='email-v'>
      <div className='log' style={backgroundStyle}>
        <div className='card-wrapper'>
          <Card className='login-header' bordered={false} style={loginCardStyle}>
            <img style={companyLogoStyle} className='login-logo' src='/logop.png' alt='Universal Logo' />
            <Meta title='Email Verification' style={{ justifyContent: 'center' }} />
            <div>
              {!invalidToken ? (
                <div>
                  <h1>SUCCESSFUL VERIFICATION</h1>
                  <br />
                  {isAdmin && <h4 style={{ fontWeight: '700' }}>YOU WILL BE REDIRECTED TO LOGIN PAGE</h4>}
                </div>
              ) : (
                <div>
                  <h1 style={{ color: 'red' }}>INVALID TOKEN</h1>
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
