import React, { useEffect, useState, useContext } from 'react';
import { Table, notification, Button, Spin, Input, Alert, Tooltip, Modal, Form } from 'antd';
import { EditOutlined, SearchOutlined, ApiFilled, EditFilled } from '@ant-design/icons';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import * as _ from 'lodash';
import Highlighter from 'react-highlight-words';

const { TextArea } = Input;

const queryClient = new QueryClient();

let filterData = {
  weekNumber: null,
  page: 1,
  pageSize: 10,
};

const initialForm = {
  text: '',
  intro: '',
  advice: '',
};

const WeekContent = () => {
  const [form] = Form.useForm();
  const [form_FS] = Form.useForm();
  const history = useHistory();
  const currentuser = useContext(UserContext);
  const [filter, setFilter] = useState(filterData);
  const [working, setWorking] = useState(false);
  const [fetchTrigger, setFetchTrigger] = useState(false);
  const [modal, setModal] = useState({ visible: false, weekNumber: null, id: null, text: '', intro: '', advice: '' });
  const [modalFirstSigns, setModalFirstSigns] = useState({ visible: false });

  useEffect(() => {
    filterData = { ...filterData, ..._.pickBy(_.get(history, 'location.state', null), _.identity) };
    setFilter(filterData);
  }, [history]);

  // react query
  async function fetchWeekContent(filter) {
    const params = _.pickBy(filter, _.identity);

    setWorking(true);

    const { data } = await Axios.get(`${SERVER_URL}/week-content`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
      params,
    });

    const resFirstSigns = await Axios.get(`${SERVER_URL}/week-content-first-signs`, {
      withCredentials: false,
      headers: { Authorization: `Bearer ${currentuser.data.token}` },
    });

    if (resFirstSigns && resFirstSigns.data) {
      form_FS.setFieldsValue({
        intro: resFirstSigns.data.intro,
        text: resFirstSigns.data.text,
        advice: resFirstSigns.data.advice,
      });
    }

    setWorking(false);

    return data;
  }

  const { data, isError } = useQuery({
    queryKey: ['weekContent', filter, fetchTrigger],
    queryFn: () => fetchWeekContent(filter),
    keepPreviousData: true,
  });

  // columns
  let searchInput;
  const getColumnSearchProps = (dataIndex) => {
    if (Array.isArray(dataIndex)) dataIndex = dataIndex.join('.');
    const prop = dataIndex;
    return {
      filteredValue:
        (_.pickBy(filter, _.identity) && _.pickBy(filter, _.identity)[prop] && [_.pickBy(filter, _.identity)[prop]]) ||
        null,
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
            <Input
              ref={(node) => {
                searchInput = node;
              }}
              placeholder='Search week number'
              value={selectedKeys && selectedKeys.length > 0 ? selectedKeys : [_.pickBy(filter, _.identity)[prop]]}
              onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={(e) => handleSearch(e, selectedKeys, confirm, prop)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />

            <Button
              type='primary'
              onClick={(e) => handleSearch(e, selectedKeys, confirm, prop)}
              size='small'
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>

            <Button onClick={(e) => handleReset(e, clearFilters, prop)} size='small' style={{ width: 90 }}>
              Reset
            </Button>
          </div>
        );
      },
      filterIcon: (filtered) => {
        filtered = !!_.pickBy(filter, _.identity)[prop];
        return <SearchOutlined style={{ color: filtered ? 'red' : '#bbb', fontSize: '1rem' }} />;
      },
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => {
            searchInput.select();
          });
        }
      },
      // sorter: true,
      // defaultSortOrder: setDefaultSort(prop),
      // sortDirections: ['ascend', 'descend'],
      render: (text) => {
        return _.pickBy(filter, _.identity)[prop] ? (
          <Highlighter
            title={text}
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[_.pickBy(filter, _.identity)[prop]]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          <span title={text}>{text}</span>
        );
      },
    };
  };

  const handleSearch = async (e, selectedKeys, confirm, dataIndex) => {
    e.preventDefault();
    confirm();
    setFilter({ ..._.pickBy(filter, _.identity), [dataIndex]: selectedKeys[0] });
  };

  const handleReset = async (e, clearFilters, prop) => {
    e.preventDefault();
    setFilter({ ...filter, [prop]: undefined });
    clearFilters();
  };

  const columns = [
    {
      title: 'WEEK NUMBER',
      dataIndex: 'weekNumber',
      key: 'weekNumber',
      align: 'center',
      ...getColumnSearchProps('weekNumber'),
    },
    {
      title: 'CREATED AT',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'right',
      render: (text, record) => new Date(record.createdAt).toLocaleString('en-US'),
    },
    {
      title: 'UPDATED AT',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      align: 'right',
      render: (text, record) => new Date(record.updatedAt).toLocaleString('en-US'),
    },
  ];

  columns.push({
    title: 'ACTION',
    key: 'action',
    align: 'center',
    render: (text, record) => {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '15px',
          }}
        >
          <div style={{ margin: '2px', padding: '4px', cursor: 'pointer' }} className='table-actions'>
            <Tooltip title='Edit week content'>
              <EditOutlined
                onClick={() => {
                  setModal({
                    visible: true,
                    weekNumber: record.weekNumber,
                    id: record._id,
                  });
                  form.setFieldsValue({ intro: record.intro, text: record.text, advice: record.advice });
                }}
              />
            </Tooltip>
          </div>
        </div>
      );
    },
  });

  const handleInitialGenerate = async () => {
    try {
      setWorking(true);

      await Axios.get(`${SERVER_URL}/week-content-generate`, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setWorking(false);
      setFetchTrigger(!fetchTrigger);

      notification.success({
        message: 'Success',
        description: 'Week content generated',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: error.response.data.message,
        placement: 'bottomRight',
      });
    }
  };

  const onOk = async () => {
    try {
      setWorking(true);

      const body = form.getFieldsValue();

      await Axios.patch(`${SERVER_URL}/week-content/${modal.id}`, body, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setWorking(false);
      setModal({ visible: false });
      setFetchTrigger(!fetchTrigger);
      form.resetFields();

      notification.success({
        message: 'Success',
        description: 'Week content updated',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem while updating week content',
        placement: 'bottomRight',
      });
    }
  };

  const onOk_FS = async () => {
    try {
      setWorking(true);

      const body = form_FS.getFieldsValue();

      await Axios.patch(`${SERVER_URL}/week-content-first-signs`, body, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      setWorking(false);
      setModalFirstSigns({ visible: false });
      setFetchTrigger(!fetchTrigger);
      form_FS.resetFields();

      notification.success({
        message: 'Success',
        description: 'First signs content updated',
        placement: 'bottomRight',
      });
    } catch (error) {
      setWorking(false);
      console.log(error);
      notification.error({
        message: 'Error',
        description: 'Problem while updating first signs content',
        placement: 'bottomRight',
      });
    }
  };

  const onChangeTable = (pagination, filters, sorter) => {
    // eslint-disable-next-line array-callback-return
    Object.keys(filters).map((prop) => {
      filter[prop] = Array.isArray(filters[prop]) ? filters[prop][0] : filters[prop];
    });

    setFilter({ ...filter, ...{ page: pagination.current, pageSize: pagination.pageSize } });
  };

  //table usersWrapper
  return (
    <div className='content-wrapper'>
      {isError && <Alert message={'Loading data problem...'} type='error' showIcon closable />}
      <Spin spinning={working} tip='Loading...'>
        <div className='actions-block' style={{ marginBottom: '8px' }}>
          <Tooltip title='Generate weeks (5 - 41) with default empty content'>
            <Button onClick={handleInitialGenerate} type='primary' disabled={data && data.length > 0} style={{ backgroundColor: data && data.length > 0 ? '#eee' : '#5A566F' }}>
              <ApiFilled style={{ marginRight: '5px' }} /> Generate Initial Week Content
            </Button>
          </Tooltip>

          <Button onClick={() => setModalFirstSigns({ visible: true })} type='primary' className='buttonCustom'>
            <EditFilled style={{ marginRight: '5px' }} /> Edit First Signs Content
          </Button>
        </div>

        <div className='table-user-okvir'>
          <Table
            size='middle'
            onChange={onChangeTable}
            bordered
            columns={columns}
            dataSource={data && [...data]}
            rowKey={(record) => record._id}
            // rowSelection={rowSelection}
            pagination={{
              pageSize: filter.pageSize || 10,
              total: data ? data.length : 0,
              current: filter.page || 1,
            }}
            scroll={{ y: 240 }}
          />
        </div>

        <Modal
          centered
          width='80%'
          visible={modal.visible}
          title={
            <span>
              EDIT - <strong>WEEK {modal.weekNumber}</strong>
            </span>
          }
          okText='SAVE'
          okButtonProps={{ className: 'buttonCustom' }}
          cancelText='CANCEL'
          maskClosable={false}
          onOk={onOk}
          onCancel={() => {
            setModal({ visible: false });
            setFetchTrigger(!fetchTrigger);
          }}
          confirmLoading={working}
        >
          <div style={{ paddingTop: '10px' }}>
            <Form form={form} initialValues={initialForm} name='weekContent'>
              <Form.Item name='intro' label='Intro'>
                <TextArea rows={5} />
              </Form.Item>

              <Form.Item name='text' label='Text'>
                <TextArea rows={5} />
              </Form.Item>

              <Form.Item name='advice' label='Advice'>
                <TextArea rows={5} />
              </Form.Item>
            </Form>
          </div>
        </Modal>

        <Modal
          centered
          width='80%'
          visible={modalFirstSigns.visible}
          title={
            <span>
              EDIT - <strong>First Signs</strong>
            </span>
          }
          okText='SAVE'
          okButtonProps={{ className: 'buttonCustom' }}
          cancelText='CANCEL'
          maskClosable={false}
          onOk={onOk_FS}
          onCancel={() => {
            setModalFirstSigns({ visible: false });
            setFetchTrigger(!fetchTrigger);
          }}
          confirmLoading={working}
        >
          <div style={{ paddingTop: '10px' }}>
            <Form form={form_FS} initialValues={initialForm} name='firstSigns'>
              <Form.Item name='intro' label='Intro'>
                <TextArea rows={5} />
              </Form.Item>

              <Form.Item name='text' label='Text'>
                <TextArea rows={5} />
              </Form.Item>

              <Form.Item name='advice' label='Advice'>
                <TextArea rows={5} />
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </Spin>
    </div>
  );
};

export default function () {
  return (
    <QueryClientProvider client={queryClient}>
      <WeekContent />
    </QueryClientProvider>
  );
}
