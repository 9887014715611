import React, { useContext, useEffect } from 'react';
import { Form, Button, Input, Select, notification } from 'antd';
import { StepBackwardOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import Axios from 'axios';
import { SERVER_URL } from '../../config';
import { UserContext } from '../../App';

const CATEGORIES = [
  { name: 'Things for birth', value: 'things_for_birth' },
  { name: 'Things for baby', value: 'things_for_baby' },
];

const ICONS = ['folder', 'suitcase', 'hospital', 'stroller', 'baby'];

const initialForm = {
  label: null,
  category: null,
  icon: null,
  checkboxes: [],
};

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 20,
    },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
};

const NewCheckboxCategory = () => {
  const currentuser = useContext(UserContext);
  const history = useHistory();
  const [form] = Form.useForm();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      const fetchCheckboxCategory = async () => {
        try {
          const { data } = await Axios.get(`${SERVER_URL}/checkbox-categories/${id}`, {
            withCredentials: false,
            headers: { Authorization: `Bearer ${currentuser.data.token}` },
          });

          if (data) {
            form.setFieldsValue(data);
          }
        } catch (error) {
          console.log(error);
          notification.error({
            message: 'Error',
            description: 'Problem while fetching checkbox category data',
            placement: 'bottomRight',
          });
        }
      };

      fetchCheckboxCategory();
    }
  }, [currentuser.data.token]);

  const handleForm = async (values) => {
    try {
      const method = id ? 'patch' : 'post';
      const route = id ? `${SERVER_URL}/checkbox-categories/${id}` : `${SERVER_URL}/checkbox-categories`;

      await Axios[method](route, values, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${currentuser.data.token}` },
      });

      notification.success({
        message: 'Success',
        description: id ? 'Checkbox group updated' : 'New checkbox group created',
        placement: 'bottomRight',
      });

      history.push('/admin/checkbox-categories');
    } catch (error) {
      console.log(error);
      notification.error({
        message: 'Error',
        description: id ? 'Problem while editing checkbox group' : 'Problem while creating new checkbox group',
        placement: 'bottomRight',
      });
    }
  };

  return (
    <div className='content-wrapper'>
      <div className='actions-block' style={{ marginBottom: '8px' }}>
        <Button onClick={() => history.push(`/admin/checkbox-categories`)} type='primary' className='buttonCustom'>
          <StepBackwardOutlined style={{ marginRight: '5px' }} />
          All Categories
        </Button>
      </div>

      <div style={{ backgroundColor: 'white', paddingTop: '10px', paddingLeft: '10px', paddingRight: '10px' }}>
        <Form
          form={form}
          initialValues={initialForm}
          onFinish={async (values) => handleForm(values)}
          name='checkboxCategory'
        >
          <Form.Item
            name='label'
            label='Label'
            rules={[
              {
                required: true,
                message: 'Must enter label for new checkbox group',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name='category'
            label='Category'
            rules={[
              {
                required: true,
                message: 'Must select category for new checkbox group',
              },
            ]}
          >
            <Select allowClear style={{ width: '200px' }}>
              {CATEGORIES.map((item, index) => (
                <Select.Option key={`${item.value}_${index}`} value={item.value}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name='icon'
            label='Icon'
            rules={[
              {
                required: true,
                message: 'Must select icon for new checkbox group',
              },
            ]}
          >
            <Select allowClear style={{ width: '200px' }}>
              {ICONS.map((item, index) => (
                <Select.Option key={`${item}_${index}`} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.List name='checkboxes'>
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => {
                  return (
                    <Form.Item
                      {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                      label={index === 0 ? 'Checkboxes' : ''}
                      required={false}
                      key={field.key}
                    >
                      <Form.Item
                        {...field}
                        validateTrigger={['onChange', 'onBlur']}
                        rules={[
                          {
                            required: true,
                            whitespace: true,
                            message: 'Please input checkbox label or delete this field.',
                          },
                        ]}
                        noStyle
                        name={[index, 'label']}
                      >
                        <Input
                          placeholder='Checkbox label'
                          style={{
                            width: '60%',
                          }}
                        />
                      </Form.Item>

                      {fields.length > 0 ? (
                        <MinusCircleOutlined
                          className='dynamic-delete-button'
                          onClick={() => remove(field.name)}
                          style={{ marginLeft: '10px', fontSize: '20px' }}
                        />
                      ) : null}
                    </Form.Item>
                  );
                })}

                <Form.Item>
                  <Button
                    type='default'
                    onClick={() => add()}
                    style={{
                      width: '20%',
                    }}
                    icon={<PlusOutlined />}
                  >
                    Add Checkbox
                  </Button>

                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item>
            <Button type='primary' htmlType='submit' className='buttonCustom'>
              SAVE
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default NewCheckboxCategory;
